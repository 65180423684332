














































import { grupo_usuarioAllDto } from "@/shared/dtos/grupo_usuarios/grupo_usuarioAllDto";
import { MessageService } from "@/shared/services/message-service";
import grupo_usuarioModule from "@/store/modules/grupo_usuario-module";
import usuarioModule from "@/store/modules/usuario-module";
import { Component, Vue } from "vue-property-decorator";
@Component({
  components: {
    MaterialCard: () => import("@/components/Cards/MaterialCard.vue"),
    UsuariosListSelect: () => import("@/views/usuarios/UsuariosListSelect.vue"),
    Xbtn: () => import("@/components/buttons/x-btn.vue"),
  },
  $_veeValidate: { validator: "new" },
})
export default class GruposFromulario extends Vue {
  created() {
    if (this.is_new()) {
      grupo_usuarioModule.onGetgrupo_usuario_ficha(new grupo_usuarioAllDto());
    } else {
      grupo_usuarioModule.getgrupo_usuario_ficha(this.$route.params.id);
    }

    usuarioModule.getusuarios();
  }

  public get usuarios() {
    return usuarioModule.usuarios;
  }

  public get datasource() {
    return grupo_usuarioModule.grupo_usuario_ficha;
  }
  public is_new() {
    return Number.parseInt(this.$route.params.id) === -1;
  }

  public aceptar() {
    this.$validator.validateAll().then((allOk) => {
      if (allOk) {
        if (this.is_new()) {
          grupo_usuarioModule
            .guardargrupo_usuarioficha(new grupo_usuarioAllDto(this.datasource))
            .then(() => this.cancelar());
        } else {
          grupo_usuarioModule
            .modificargrupo_usuarioficha(this.datasource)
            .then(() => this.cancelar());
        }
      }
    });
  }

  public cancelar() {
    this.$router.back();
  }

  eliminar() {
    MessageService.confirm(
      "¿Seguro que desea eliminar el grupo?",
      "Eliminar",
      (cb: boolean) => {
        if (cb) {
          grupo_usuarioModule
            .eliminargrupo_usuarioficha(this.datasource)
            .then(() => this.$router.back())
            .catch(() => {
              MessageService.toast(
                "No se ha podido eliminar, hay datos asociados a este grupo de usuarios",
                MessageService.TypeWarning
              );
            });
        }
      }
    );
  }
}
